// Export all sagas
import { all } from "redux-saga/effects";
import { watchUsersAsync } from "../sagas/modules/AdminPanel/usersSagas";
import { watchLoginAsync } from "./auth/authSagas";
import { watchPermissionAsync } from "./auth/permissionsSagas";
import { watchModulesAsync } from "./menuSagas";
import { watchOrdersAsync } from "./modules/OrdersSagas";
import { watchSalesContractAmendsAsync } from "./modules/SCAmendSagas";
import { watchApprovalPanelMembersAsync } from "./modules/approvalPanelMembersSaga";
import { watchApprovalPanelsAsync } from "./modules/approvalPanelsSaga";
import { watchBankAccountsAsync } from "./modules/bankAccountsSagas";
import { watchBanksAsync } from "./modules/banksSagas";
import { watchBuyerBrandsAsync } from "./modules/buyerBrandsSagas";
import { watchBuyerDepartmentsAsync } from "./modules/buyerDeptSagas";
import { watchBuyerTermsAsync } from "./modules/buyerTermsSagas";
import { watchBuyersAsync } from "./modules/buyersSagas";
import { watchColorsAsync } from "./modules/colorsSagas";
import { watchCommissionDetailsAsync } from "./modules/commissionDetailsSagas";
import { watchCommissionsAsync } from "./modules/commissionsSagas";
import { watchCompaniesAsync } from "./modules/companiesSagas";
import { watchCountriesAsync } from "./modules/countriesSagas";
import { watchCouriersAsync } from "./modules/couriersSagas";
import { watchCurrenciesAsync } from "./modules/currenciesSagas";
import { watchExFactoriesAsync } from "./modules/exFactoriesSagas";
import { watchExFactoryDetailsAsync } from "./modules/exFactoryDetailsSagas";
import { watchExFactoryInfosAsync } from "./modules/exFactoryInfoSagas";
import { watchFabricTypesAsync } from "./modules/fabricTypeSagas";
import { watchFabricsAsync } from "./modules/fabricsSagas";
import { watchFactoryOrderDetailsAsync } from "./modules/factoryOrderDetailsSagas";
import { watchFactoryOrdersAsync } from "./modules/factoryOrdersSagas";
import { watchFactoryShipmentDetailsAsync } from "./modules/factoryShipmentDetailsSagas";
import { watchFobTypesAsync } from "./modules/fobTypesSagas";
import { watchLCHeaderDetailsAsync } from "./modules/lcHeaderDetailSaga";
import { watchLCHeadersAsync } from "./modules/lcHeaderSaga";
import { watchOrderDetailsAsync } from "./modules/orderDetailsSagas";
import { watchOverseasOfficesAsync } from "./modules/overseaSagas";
import { watchPaymentTermsAsync } from "./modules/paymentTermSagas";
import { watchPortsAsync } from "./modules/portsSagas";
import { watchProductsAsync } from "./modules/productSagas";
import { watchProductTypesAsync } from "./modules/productTypeSagas";
import { watchSalesContractActionsAsync } from "./modules/salesContractActionSagas";
import { watchSalesContractsAsync } from "./modules/salesContractSagas";
import { watchSeasonsAsync } from "./modules/seasonSagas";
import { watchShipmentDetailsAsync } from "./modules/shipmentDetailsSagas";
import { watchSizeSetsAsync } from "./modules/sizeSetsSagas";
import { watchSuppliersAsync } from "./modules/suppliersSagas";
import { watchTeamsAsync } from "./modules/teamSagas";
import { watchTnaActionsAsync } from "./modules/tnaActionsSagas";
import { watchTnaPlanDetailAsync } from "./modules/tnaPlanDetailsSagas";
import { watchTnaPlanHeaderAsync } from "./modules/tnaPlanHeaderSagas";
import { watchTnaTemplateActionsAsync } from "./modules/tnaTemplateActionsSagas";
import { watchTnaTemplatesAsync } from "./modules/tnaTemplatesSagas";
import { watchUserAccessesAsync } from "./modules/userAccessSagas";
import { watchUserRolesAsync } from "./modules/userRolesSagas";

import { watchDiscountDetailsAsync } from "./modules/discountDetailsSaga";
import { watchDiscountsAsync } from "./modules/discountsSaga";
import { watchFactoryInvoicesAsync } from "./modules/factoryInvoiceSaga";

import { watchRDLInvoicesAsync } from "./modules/RDLInvoiceSaga";

import { watchTTAdvanceDetailsAsync } from "./modules/TTAdvanceDetailSaga";
import { watchTTAdvancesAsync } from "./modules/TTAdvanceSaga";
import { watchApprovalFormSetupsAsync } from "./modules/approvalFormSetupSaga";
import { watchCommissionInvoicesAsync } from "./modules/commissionInvoiceSaga";
import { watchFreightTermsAsync } from "./modules/freightTermSaga";
import { watchProceedRealizationDetailsAsync } from "./modules/proceedRealizationDetailsSaga";
import { watchProceedRealizationsAsync } from "./modules/proceedRealizationSaga";

import { watchUserLevelPrivilegesAsync } from "./modules/userLevelPrivilegesSaga";
import { watchUserLevelsAsync } from "./modules/userLevelsSaga";

import { watchAccountTypesAsync } from "./modules/accountTypesSagas";
import { watchAccountsAsync } from "./modules/accountsSagas";
import { watchCategoriesAsync } from "./modules/categoriesSagas";
import { watchLoadingPointsAsync } from "./modules/loadingPointSagas";
import { watchMeasuringUnitsAsync } from "./modules/measuringUnitsSagas";
import { watchProductBrandsAsync } from "./modules/productBrandsSagas";
import { watchSubCategoriesAsync } from "./modules/subCategoriesSagas";

export default function* rootSaga() {
  yield all([
    watchCategoriesAsync(),
    watchSubCategoriesAsync(),
    watchMeasuringUnitsAsync(),
    watchUsersAsync(),
    watchModulesAsync(),
    watchBanksAsync(),
    watchSuppliersAsync(),
    watchBuyersAsync(),
    watchOverseasOfficesAsync(),
    watchColorsAsync(),
    watchAccountsAsync(),
    watchLoadingPointsAsync(),
    watchAccountTypesAsync(),
    watchBuyerBrandsAsync(),
    watchBuyerDepartmentsAsync(),
    watchSizeSetsAsync(),
    watchSeasonsAsync(),
    watchProductTypesAsync(),
    watchProductsAsync(),
    watchBankAccountsAsync(),
    watchBuyerTermsAsync(),
    watchCompaniesAsync(),
    watchCouriersAsync(),
    watchExFactoriesAsync(),
    watchFabricsAsync(),
    watchPortsAsync(),
    watchFabricTypesAsync(),
    watchUserRolesAsync(),
    watchUserAccessesAsync(),
    watchFobTypesAsync(),
    watchCurrenciesAsync(),
    watchCountriesAsync(),
    watchLoginAsync(),
    watchPermissionAsync(),
    watchOrdersAsync(),
    watchPaymentTermsAsync(),
    watchTeamsAsync(),
    watchOrderDetailsAsync(),
    watchShipmentDetailsAsync(),
    watchFactoryOrdersAsync(),
    watchTnaActionsAsync(),
    watchFactoryOrderDetailsAsync(),
    watchFactoryShipmentDetailsAsync(),
    watchTnaTemplateActionsAsync(),
    watchTnaTemplatesAsync(),
    watchTnaPlanHeaderAsync(),
    watchTnaPlanDetailAsync(),
    watchExFactoryInfosAsync(),
    watchSalesContractActionsAsync(),
    watchSalesContractsAsync(),
    watchCommissionsAsync(),
    watchExFactoryDetailsAsync(),
    watchLCHeadersAsync(),
    watchLCHeaderDetailsAsync(),
    watchSalesContractAmendsAsync(),
    watchCommissionDetailsAsync(),
    watchApprovalPanelMembersAsync(),
    watchApprovalPanelsAsync(),
    watchDiscountDetailsAsync(),
    watchDiscountsAsync(),
    watchFactoryInvoicesAsync(),
    watchRDLInvoicesAsync(),
    watchTTAdvancesAsync(),
    watchTTAdvanceDetailsAsync(),
    watchApprovalFormSetupsAsync(),
    watchCommissionInvoicesAsync(),
    watchProceedRealizationsAsync(),
    watchProceedRealizationDetailsAsync(),
    watchFreightTermsAsync(),
    watchUserLevelPrivilegesAsync(),
    watchUserLevelsAsync(),
    watchProductBrandsAsync(),
  ]);
}

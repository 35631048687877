import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
// Create styles
const Quixote = ({ invoiceData, companyInfo, getInfo, getSummInfo }) => {
  const reportData = invoiceData ? invoiceData : {};

  const refineTableData = reportData
    ? Object.keys(reportData)
        .map((key) => reportData[key])
        .sort(function (a, b) {
          if (a.account_name < b.account_name) {
            return -1;
          }
          if (a.account_name > b.account_name) {
            return 1;
          }
          return 0;
        })
    : [];

  let totalBalance = refineTableData?.reduce(
    (prev, curr) => prev + Number(curr.balance),
    0
  );

  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0]; // yyyy/mm/dd

  return (
    <Document>
      <Page orientation="portrait" size="A4" style={styles.body} wrap>
        <View wrap={false}>
          <View
            style={{
              borderBottom: "3px solid black",
              paddingBottom: 5,
            }}
          >
            <View style={{ margin: "auto" }}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: 900,
                  fontFamily: "Times",
                }}
              >
                {companyInfo?.[0]?.["company_name"]}
              </Text>
            </View>
            <View style={{ margin: "auto" }}>
              <Text style={{ fontSize: 12 }}>
                {companyInfo?.[0]?.["street"]}
                {", "}
                {companyInfo?.[0]?.["city"]}
                {", "}
                {companyInfo?.[0]?.["country"]}
              </Text>
            </View>
          </View>

          <View
            style={{
              height: "auto",
              marginTop: "2px",
            }}
          >
            <Text
              style={{
                fontSize: "13px",
                fontWeight: "bold",
                fontFamily: "Times",
                textAlign: "center",
              }}
            >
              Outstanding Report
            </Text>
            <Text
              style={{
                flexDirection: "row",
                justifyContent: "center",
                margin: "auto",
                borderBottom: "1px solid black",
                width: 130,
              }}
            >
              {""}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <View>
              <Text style={{ fontSize: "9px" }}>
                <Text style={{ fontWeight: 900, fontFamily: "Times" }}>
                  Print Date:
                </Text>{" "}
                {new Date(defaultValue).toLocaleDateString(["es-CL", "id"])}
              </Text>
              <Text style={{ fontSize: "9px" }}>
                <Text style={{ fontWeight: 900, fontFamily: "Times" }}>
                  Print By:
                </Text>{" "}
                <Text style={{ fontSize: "9px" }}>{`${
                  getInfo?.first_name ? getInfo?.first_name : null
                } ${getInfo?.last_name ? getInfo?.last_name : ""}`}</Text>
              </Text>
            </View>
          </View>
        </View>
        {/* TABLE PART */}

        <View style={{ marginTop: 30 }}>
          <View
            style={{
              border: "1px solid black",
              flexDirection: "row",
              width: "100%",
              backgroundColor: "#d3cfcf",
            }}
            fixed
          >
            <Text
              style={{
                padding: "5px",
                width: "25%",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Account Name
            </Text>
            <Text
              style={{
                padding: "5px",
                width: "25%",
                fontSize: "10px",
                fontFamily: "Times",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Address
            </Text>

            <Text
              style={{
                padding: "5px",
                width: "25%",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Phone No.
            </Text>
            <Text
              style={{
                padding: "5px",
                fontSize: "10px",
                width: "25%",
                fontWeight: "bold",
                fontFamily: "Times",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              Balance
            </Text>
          </View>
          <View
            style={{
              width: "100%",
            }}
          >
            {refineTableData && refineTableData?.length
              ? refineTableData?.map((i2, i) => {
                  return (
                    <View
                      key={i}
                      wrap={false}
                      // break={i > 13}
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "1px solid black",
                        borderRight: "1px solid black",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <Text
                        style={{
                          padding: "5px",
                          fontSize: "8px",
                          height: "auto",
                          width: "25%",
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {i2?.account_name}
                      </Text>

                      <Text
                        style={{
                          padding: "5px",
                          fontSize: "8px",
                          height: "auto",
                          width: "25%",
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {i2?.address}
                      </Text>
                      <Text
                        style={{
                          padding: "5px",
                          fontSize: "8px",
                          height: "auto",
                          width: "25%",
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {i2?.phone_no}
                      </Text>
                      <Text
                        style={{
                          padding: "5px",
                          fontSize: "8px",
                          height: "auto",
                          width: "25%",
                          textAlign: "center",
                        }}
                      >
                        {Number(i2?.balance).toFixed(2)}
                      </Text>
                    </View>
                  );
                })
              : null}
          </View>
        </View>

        <View
          wrap={false}
          style={{
            borderBottom: "1px solid black",
            borderLeft: "1px solid black",
            borderTop: "0.5px solid black",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Text
            style={{
              padding: "5px",
              fontSize: "10px",
              height: "auto",
              width: "25%",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {"Total"}
          </Text>

          <Text
            style={{
              padding: "5px",
              fontSize: "10px",
              height: "auto",
              width: "24.92%",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {""}
          </Text>

          <Text
            style={{
              padding: "5px",
              fontSize: "10px",
              height: "auto",
              width: "25%",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {""}
          </Text>
          <Text
            style={{
              padding: "5px",
              fontSize: "10px",
              height: "auto",
              width: "25%",
              color: "green",
              borderRight: "1px solid black",
              fontWeight: "bold",
              fontFamily: "Times",
              textAlign: "center",
            }}
          >
            {Number(totalBalance)?.toFixed(2)}
          </Text>
        </View>

        {/* total Of summary */}
        {/* <View style={{ marginTop: 40 }}>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
              fontFamily: "Times",
              fontWeight: "bold",
            }}
          >
            Summary
          </Text>
          <View
            style={{
              border: "1px solid black",
              flexDirection: "row",
              width: "440px",
              backgroundColor: "#d3cfcf",
            }}
            fixed
          >
            <Text
              style={{
                padding: "5px",
                width: "110px",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Purchase Value
            </Text>
            <Text
              style={{
                padding: "5px",
                width: "110px",
                fontSize: "10px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Sale Value
            </Text>
            <Text
              style={{
                padding: "5px",
                fontSize: "10px",
                width: "110px",
                fontWeight: "bold",
                fontFamily: "Times",
                alignItems: "center",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Expense Value
            </Text>
            <Text
              style={{
                padding: "5px",
                fontSize: "10px",
                width: "110px",
                fontWeight: "bold",
                fontFamily: "Times",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              Balance
            </Text>
          </View>
          <View
            wrap={false}
            style={{
              borderBottom: "1px solid black",
              borderLeft: "1px solid black",
              borderTop: "0.5px solid black",
              flexDirection: "row",
              width: "440px",
            }}
          >
            <Text
              style={{
                padding: "5px",
                fontSize: "10px",
                height: "auto",
                width: "109.5px",
                color: "green",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              {Number(totalPurchaseValue)?.toFixed(2)}
            </Text>

            <Text
              style={{
                padding: "5px",
                fontSize: "10px",
                height: "auto",
                width: "109.5px",
                color: "green",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              {Number(totalSaleValue)?.toFixed(2)}
            </Text>
            <Text
              style={{
                padding: "5px",
                fontSize: "10px",
                height: "auto",
                width: "109.6px",
                color: "green",
                borderRight: "1px solid black",
                fontWeight: "bold",
                fontFamily: "Times",
                textAlign: "center",
              }}
            >
              {Number(totalExpenseValue)?.toFixed(2)}
            </Text>
            <Text
              style={{
                padding: "5px",
                fontSize: "10px",
                height: "auto",
                width: "110px",
                color: "green",
                borderRight: "1px solid black",
                fontWeight: "bold",
                fontFamily: "Times",
                textAlign: "center",
              }}
            >
              {(
                Number(totalPurchaseValue) +
                Number(totalExpenseValue) -
                Number(totalSaleValue)?.toFixed(2)
              )?.toFixed(2)}
            </Text>
          </View>
        </View> */}

        {/* Signature part */}
        <View
          wrap={false}
          fixed
          style={{
            flexDirection: "row",
            bottom: 0,
            marginTop: 60,
            justifyContent: "space-around",
          }}
        >
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Received Name
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Customer Seal & Signature
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Authorised Signature
              </Text>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export const chunkSubstr = (str, size) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};

Font.registerHyphenationCallback((word) => {
  if (word.length > 16) {
    return chunkSubstr(word, 14);
  } else {
    return [word];
  }
});

Font.register({
  family: "Arial",
  fonts: [
    {
      src: "/assets/fonts/ARIALN.TTF",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/ARIALN.TTF",
    },
    {
      src: "/assets/fonts/ARIALBD.TTF",
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Helvetica",
  fonts: [
    {
      src: "/assets/fonts/Helvetica.ttf",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/Helvetica Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Times",
  fonts: [
    {
      src: "/assets/fonts/Times-Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  body: {
    paddingTop: 25,
    paddingBottom: 60,
    paddingHorizontal: 20,
    fontFamily: "Helvetica",
  },

  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;

import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
// Create styles
const Quixote = ({ invoiceData, companyInfo, getInfo, getCustomerInfo }) => {
  const reportData = invoiceData && invoiceData.length ? invoiceData : [];

  console.log("reportData", reportData);

  let grandtotalOrderQty = 0;
  let grandtotalReceivedAmt = 0;
  let grandtotalSalesAmt = 0;
  let grandtotalBalanceAmt = 0;

  let count = 0;

  const subtotalAmt =
    reportData && reportData.length
      ? reportData.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.amount),
          0
        )
      : null;

  const subtotalQty =
    reportData && reportData.length
      ? reportData.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.quantity),
          0
        )
      : null;

  const BuyerData = ({ i2 }) => {
    return (
      <View
        wrap={false}
        break={count > 13}
        style={{
          borderBottom: "1px solid black",
          borderLeft: "1px solid black",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "10%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.transaction_date}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "15%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.transaction_no}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "20%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.customer_name}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "15%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.product_name}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "10%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.uom}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "10%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.quantity}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "10%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.rate}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "10%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.transport_no}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "10%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.transport_cost}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "10%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2.amount}
        </Text>
      </View>
    );
  };

  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0]; // yyyy/mm/dd

  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.body} wrap>
        {/* Invoice Image & Header PART */}

        <View>
          <View wrap={false}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                backgroundColor: "#dcdcdc",
                alignItems: "center",
                // marginTop: -20,
                // marginBottom: "20px",
              }}
            >
              <View>
                <Image
                  style={{
                    width: "90px",
                    height: "100px",
                    // textAlign: "center",
                    // margin: "auto",
                    marginTop: -20,
                  }}
                  src={`https://wholesale-api.mnbtradersbd.com/api/upload-images/${companyInfo?.[0]?.["logo"]}`}
                />
              </View>
              <View>
                <Text
                  style={{
                    fontSize: 35,
                    fontWeight: "bold",
                    paddingTop: 4,
                  }}
                >
                  {companyInfo?.[0]?.["company_name"]}
                </Text>
              </View>
              <View style={{ paddingRight: 2 }}>
                <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                  Office:
                </Text>
                <Text style={{ fontSize: 10, fontWeight: "bold" }}>
                  City: {companyInfo?.[0]?.["city"]}
                </Text>
                <Text style={{ fontSize: 10, fontWeight: "bold" }}>
                  Street: {companyInfo?.[0]?.["street"]}
                </Text>
                <Text style={{ fontSize: 10, fontWeight: "bold" }}>
                  Phone: {companyInfo?.[0]?.["phone_no"]}
                </Text>
              </View>
            </View>
            <View
              style={{
                margin: "auto",
                height: "auto",
                marginTop: "2px",
                // marginTop: -20,
                marginBottom: "20px",
                textAlign: "center",
                width: "400px",
                border: "1px solid black",
              }}
            >
              <Text
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  borderBottom: "1px solid black",
                  textAlign: "center",
                  padding: "4px",
                }}
              >
                Sales Register
              </Text>
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  textAlign: "center",
                  padding: "4px",
                }}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  From{" "}
                </Text>{" "}
                {getCustomerInfo && getCustomerInfo?.["p_from_date"]}{" "}
                <Text
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  To{" "}
                </Text>{" "}
                {getCustomerInfo && getCustomerInfo?.["p_to_date"]}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: "-60px",
                marginBottom: "20px",
              }}
            >
              <View>
                <Text style={{ fontSize: "9px", fontWeight: 900 }}>
                  Print Date :{" "}
                  {new Date(defaultValue).toLocaleDateString(["es-CL", "id"])}
                </Text>
                <Text style={{ fontSize: "9px", fontWeight: 900 }}>
                  Print by : {""}
                </Text>
              </View>
            </View>

            {/* Header Info */}
            {/* <View
                    style={{
                      height: "70px",
                      marginTop: "20px",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <View>
                      <Text
                        style={{
                          fontSize: "11px",
                          fontWeight: 900,
                          paddingBottom: 2,
                        }}
                      >
                        Account Name
                      </Text>
                      <Text
                        style={{
                          fontSize: "11px",
                          fontWeight: 900,
                          paddingBottom: 2,
                        }}
                      >
                        Address
                      </Text>
                    </View>

                    <View>
                      <Text style={{ fontSize: "11px", paddingBottom: 2 }}>
                        : {newArr?.[0]?.["account_name"]}
                      </Text>
                      <Text style={{ fontSize: "11px", paddingBottom: 2 }}>
                        : {newArr?.[0]?.["address"]}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          fontSize: "11px",
                          fontWeight: 900,
                          paddingBottom: 2,
                        }}
                      >
                        Contact
                      </Text>
                      <Text
                        style={{
                          fontSize: "11px",
                          fontWeight: 900,
                          paddingBottom: 2,
                        }}
                      >
                        Proprietor/Ref Name
                      </Text>
                    </View>
                    <View>
                      <Text style={{ fontSize: "11px", paddingBottom: 2 }}>
                        : {newArr?.[0]?.["phone_no"]}
                      </Text>
                      <Text style={{ fontSize: "11px", paddingBottom: 2 }}>
                        : {newArr?.[0]?.["ref_name"]}
                      </Text>
                    </View>
                  </View> */}
          </View>
          {/* TABLE PART */}

          <View style={{ marginTop: "20px" }}>
            <View
              style={{
                border: "1px solid black",
                flexDirection: "row",
                width: "100%",
                backgroundColor: "#d3cfcf",
              }}
              fixed
            >
              <Text
                style={{
                  padding: "9px 0",
                  width: "10%",
                  fontSize: "9px",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Date
              </Text>
              <Text
                style={{
                  padding: "9px 0",
                  width: "15%",
                  fontSize: "9px",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Sale No.
              </Text>

              <Text
                style={{
                  padding: "9px 0 0 0",
                  width: "20%",
                  fontSize: "9px",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Customer Name
              </Text>

              <Text
                style={{
                  padding: "9px 0 0 0",
                  fontSize: "9px",
                  width: "15%",
                  fontWeight: "bold",
                  alignItems: "center",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Item
              </Text>
              <Text
                style={{
                  padding: "9px 0 0 0",
                  width: "10%",
                  borderRight: "1px solid black",
                  fontSize: "9px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Uom
              </Text>
              <Text
                style={{
                  padding: "9px 0 0 0",
                  width: "10%",
                  fontSize: "9px",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Quantity
              </Text>
              <Text
                style={{
                  padding: "9px 0",
                  fontSize: "9px",
                  width: "10%",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Rate
              </Text>

              <Text
                style={{
                  padding: "9px 0",
                  fontSize: "9px",
                  width: "10%",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Transport No.
              </Text>
              <Text
                style={{
                  padding: "9px 0",
                  fontSize: "9px",
                  width: "10%",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Transport Cost
              </Text>
              <Text
                style={{
                  padding: "9px 0 0 0",
                  fontSize: "9px",
                  width: "10%",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Amount
              </Text>
            </View>

            <View>
              <View
                style={{
                  // flexDirection: "column",
                  width: "100%",
                }}
              >
                {/* <View
                        wrap={false}
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                          border: "1px solid black",
                          height: "auto",
                        }}
                      >
                        <Text
                          style={{
                            padding: "5px 0 5px 10px",
                            fontSize: "11px",
                            fontWeight: "bold",
                            color: "black",
                            height: "auto",
                          }}
                        >
                          {filtering?.[0]?.["purticulars"]}
                        </Text>
                        <Text
                          style={{
                            padding: "5px 2px 5px 0",
                            fontSize: "11px",
                            fontWeight: "bold",
                            color: "black",
                            height: "auto",
                          }}
                        >
                          {filtering?.[0]?.["cumulative_sum"]
                            ? filtering?.[0]?.["cumulative_sum"]
                            : "0.00"}
                        </Text>
                      </View> */}

                {reportData && reportData?.length
                  ? reportData?.map((i2, i) => {
                      count = count + 1;
                      return (
                        <View key={i}>
                          <BuyerData key={i} i2={i2} />
                        </View>
                      );
                    })
                  : null}
                <View
                  style={{
                    borderBottom: "1px solid black",
                    borderLeft: "1px solid black",
                    flexDirection: "row",
                    width: "100%",
                  }}
                  wrap={false}
                >
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "10%",
                      color: "red",
                      fontWeight: "bold",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {"Total"}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "15%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {""}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "20%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {""}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "15%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {""}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {""}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      color: "red",
                      fontWeight: "bold",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {subtotalQty}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {""}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {""}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {""}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      color: "red",
                      fontWeight: "bold",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {subtotalAmt}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        {/* Signature part */}
        <View
          fixed
          style={{
            flexDirection: "row",
            bottom: 0,
            marginTop: 60,
            justifyContent: "space-around",
          }}
        >
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Received Name
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Customer Seal & Signature
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Authorised Signature
              </Text>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export const chunkSubstr = (str, size) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};

Font.registerHyphenationCallback((word) => {
  if (word.length > 16) {
    return chunkSubstr(word, 14);
  } else {
    return [word];
  }
});

Font.register({
  family: "Arial",
  fonts: [
    {
      src: "/assets/fonts/ARIALN.TTF",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/ARIALN.TTF",
    },
    {
      src: "/assets/fonts/ARIALBD.TTF",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  body: {
    paddingTop: 25,
    paddingBottom: 60,
    paddingHorizontal: 20,
    minWidth: "110vw",
    fontFamily: "Arial",
  },

  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;

import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
// Create styles
const Quixote = ({ invoiceData, companyInfo, getInfo, getSummInfo }) => {
  const reportData = invoiceData ? invoiceData : {};

  const refineTableData = reportData
    ? Object.keys(reportData).map((key) => reportData[key])
    : [];

  const refineLiabilityTableData =
    refineTableData &&
    refineTableData?.filter((item) =>
      item?.nature ? item?.nature?.toLowerCase().includes("l") : null
    );

  const refineAssetTableData =
    refineTableData &&
    refineTableData?.filter((item) =>
      item?.nature ? item?.nature?.toLowerCase().includes("a") : null
    );

  const totalLiability = refineLiabilityTableData
    ? refineLiabilityTableData?.filter(
        (item) => item.nature?.toLowerCase() === "tl"
      )
    : null;
  const totalAsset = refineAssetTableData
    ? refineAssetTableData?.filter(
        (item) => item.nature?.toLowerCase() === "ta"
      )
    : null;

  const grandTotalValue =
    totalLiability && totalAsset
      ? Number(totalAsset?.[0]?.bal_amt) - Number(totalLiability?.[0]?.bal_amt)
      : "0.00";

  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0]; // yyyy/mm/dd

  return (
    <Document>
      <Page orientation="portrait" size="A4" style={styles.body} wrap>
        <View wrap={false}>
          <View
            style={{
              borderBottom: "3px solid black",
              paddingBottom: 5,
            }}
          >
            <View style={{ margin: "auto" }}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: 900,
                  fontFamily: "Times",
                }}
              >
                {companyInfo?.[0]?.["company_name"]}
              </Text>
            </View>
            <View style={{ margin: "auto" }}>
              <Text style={{ fontSize: 12 }}>
                {companyInfo?.[0]?.["street"]}
                {", "}
                {companyInfo?.[0]?.["city"]}
                {", "}
                {companyInfo?.[0]?.["country"]}
              </Text>
            </View>
          </View>

          <View
            style={{
              height: "auto",
              marginTop: "2px",
            }}
          >
            <Text
              style={{
                fontSize: "13px",
                fontWeight: "bold",
                fontFamily: "Times",
                textAlign: "center",
              }}
            >
              Balance Sheet
            </Text>
            <Text
              style={{
                flexDirection: "row",
                justifyContent: "center",
                margin: "auto",
                borderBottom: "1px solid black",
                width: 150,
              }}
            >
              {""}
            </Text>
            <Text
              style={{
                fontSize: "10px",
                textAlign: "center",
                padding: "1px",
              }}
            >
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Times",
                }}
              >
                From
              </Text>{" "}
              {getSummInfo &&
                new Date(getSummInfo?.["p_from_date"]).toLocaleDateString([
                  "es-CL",
                  "id",
                ])}
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Times",
                }}
              >
                {" "}
                To
              </Text>{" "}
              {getSummInfo &&
                new Date(getSummInfo?.["p_to_date"]).toLocaleDateString([
                  "es-CL",
                  "id",
                ])}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <View>
              <Text style={{ fontSize: "9px" }}>
                <Text style={{ fontWeight: 900, fontFamily: "Times" }}>
                  Print Date:
                </Text>{" "}
                {new Date(defaultValue).toLocaleDateString(["es-CL", "id"])}
              </Text>
              <Text style={{ fontSize: "9px" }}>
                <Text style={{ fontWeight: 900, fontFamily: "Times" }}>
                  Print By:
                </Text>{" "}
              </Text>
            </View>
          </View>
        </View>
        {/* TABLE PART */}

        <View style={{ marginTop: 30 }}>
          <View
            style={{
              border: "1px solid black",
              flexDirection: "row",
              width: "100%",
              backgroundColor: "#d3cfcf",
            }}
            fixed
          >
            <Text
              style={{
                padding: "5px",
                width: "40%",
                fontSize: "11px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Particular
            </Text>
            <Text
              style={{
                padding: "5px",
                width: "15%",
                fontSize: "11px",
                fontFamily: "Times",
                borderRight: "1px solid black",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Openning Amount
            </Text>
            <Text
              style={{
                padding: "5px",
                width: "15%",
                fontSize: "11px",
                fontFamily: "Times",
                borderRight: "1px solid black",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Dr Amount
            </Text>
            <Text
              style={{
                padding: "5px",
                width: "15%",
                fontSize: "11px",
                fontFamily: "Times",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Cr Amount
            </Text>
            <Text
              style={{
                padding: "5px",
                width: "15%",
                fontSize: "11px",
                fontFamily: "Times",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Balance
            </Text>
          </View>
          <View
            style={{
              width: "100%",
            }}
          >
            <View
              style={{
                width: "100%",
                borderLeft: "1px solid black",
                borderBottom: "1px solid black",
                borderRight: "1px solid black",
                padding: "5px",
              }}
            >
              <Text
                style={{
                  fontSize: "11px",
                  fontFamily: "Times",
                  fontWeight: "bold",
                }}
              >
                Liability
              </Text>
            </View>
            {refineLiabilityTableData && refineLiabilityTableData?.length
              ? refineLiabilityTableData?.map((i2, i) => {
                  return (
                    <View
                      key={i}
                      wrap={false}
                      break={i > 13}
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "1px solid black",
                        borderRight: "1px solid black",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <Text
                        style={{
                          padding: "5px",
                          fontSize: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? 10
                              : 8
                          }`,
                          fontWeight: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? 900
                              : "normal"
                          }`,
                          fontFamily: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? "Times"
                              : "Helvetica"
                          }`,
                          paddingLeft: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? 5
                              : 20
                          }`,
                          height: "auto",
                          width: "40%",
                          borderRight: "1px solid black",
                          // textAlign: "center",
                        }}
                      >
                        {i2?.account_name}
                      </Text>

                      <Text
                        style={{
                          padding: "5px",
                          fontSize: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? 10
                              : 8
                          }`,
                          fontWeight: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? 900
                              : "normal"
                          }`,
                          fontFamily: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? "Times"
                              : "Helvetica"
                          }`,
                          height: "auto",
                          width: "15%",
                          borderRight: "1px solid black",
                          textAlign: "right",
                        }}
                      >
                        {Number(i2?.op_amt).toFixed(2)}
                      </Text>

                      <Text
                        style={{
                          padding: "5px",
                          fontSize: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? 10
                              : 8
                          }`,
                          fontWeight: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? 900
                              : "normal"
                          }`,
                          fontFamily: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? "Times"
                              : "Helvetica"
                          }`,
                          height: "auto",
                          width: "15%",
                          borderRight: "1px solid black",
                          textAlign: "right",
                        }}
                      >
                        {Number(i2?.dr_amt).toFixed(2)}
                      </Text>

                      <Text
                        style={{
                          padding: "5px",
                          fontSize: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? 10
                              : 8
                          }`,
                          fontWeight: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? 900
                              : "normal"
                          }`,
                          fontFamily: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? "Times"
                              : "Helvetica"
                          }`,
                          height: "auto",
                          width: "15%",
                          borderRight: "1px solid black",
                          textAlign: "right",
                        }}
                      >
                        {Number(i2?.cr_amt).toFixed(2)}
                      </Text>

                      <Text
                        style={{
                          padding: "5px",
                          fontSize: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? 10
                              : 8
                          }`,
                          fontWeight: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? 900
                              : "normal"
                          }`,
                          fontFamily: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? "Times"
                              : "Helvetica"
                          }`,
                          height: "auto",
                          width: "15%",
                          textAlign: "right",
                        }}
                      >
                        {Number(i2?.bal_amt).toFixed(2)}
                      </Text>
                    </View>
                  );
                })
              : null}

            <View
              style={{
                width: "100%",
                borderLeft: "1px solid black",
                borderBottom: "1px solid black",
                borderRight: "1px solid black",
                padding: "5px",
              }}
            >
              <Text
                style={{
                  fontSize: "11px",
                  fontFamily: "Times",
                  fontWeight: "bold",
                }}
              >
                Asset
              </Text>
            </View>
            {refineAssetTableData && refineAssetTableData?.length
              ? refineAssetTableData?.map((i2, i) => {
                  return (
                    <View
                      key={i}
                      wrap={false}
                      break={i > 13}
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "1px solid black",
                        borderRight: "1px solid black",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <Text
                        style={{
                          padding: "5px",
                          fontSize: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? 10
                              : 8
                          }`,
                          fontWeight: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? 900
                              : "normal"
                          }`,
                          fontFamily: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? "Times"
                              : "Helvetica"
                          }`,
                          paddingLeft: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? 5
                              : 20
                          }`,
                          height: "auto",
                          width: "40%",
                          borderRight: "1px solid black",
                          // textAlign: "center",
                        }}
                      >
                        {i2?.account_name}
                      </Text>

                      <Text
                        style={{
                          padding: "5px",
                          fontSize: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? 10
                              : 8
                          }`,
                          fontWeight: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? 900
                              : "normal"
                          }`,
                          fontFamily: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? "Times"
                              : "Helvetica"
                          }`,
                          height: "auto",
                          width: "15%",
                          borderRight: "1px solid black",
                          textAlign: "right",
                        }}
                      >
                        {Number(i2?.op_amt).toFixed(2)}
                      </Text>

                      <Text
                        style={{
                          padding: "5px",
                          fontSize: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? 10
                              : 8
                          }`,
                          fontWeight: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? 900
                              : "normal"
                          }`,
                          fontFamily: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? "Times"
                              : "Helvetica"
                          }`,
                          height: "auto",
                          width: "15%",
                          borderRight: "1px solid black",
                          textAlign: "right",
                        }}
                      >
                        {Number(i2?.dr_amt).toFixed(2)}
                      </Text>

                      <Text
                        style={{
                          padding: "5px",
                          fontSize: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? 10
                              : 8
                          }`,
                          fontWeight: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? 900
                              : "normal"
                          }`,
                          fontFamily: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? "Times"
                              : "Helvetica"
                          }`,
                          height: "auto",
                          width: "15%",
                          borderRight: "1px solid black",
                          textAlign: "right",
                        }}
                      >
                        {Number(i2?.cr_amt).toFixed(2)}
                      </Text>

                      <Text
                        style={{
                          padding: "5px",
                          fontSize: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? 10
                              : 8
                          }`,
                          fontWeight: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? 900
                              : "normal"
                          }`,
                          fontFamily: `${
                            i2?.account_name &&
                            i2?.account_name?.toLowerCase()?.includes("total")
                              ? "Times"
                              : "Helvetica"
                          }`,
                          height: "auto",
                          width: "15%",
                          textAlign: "right",
                        }}
                      >
                        {Number(i2?.bal_amt).toFixed(2)}
                      </Text>
                    </View>
                  );
                })
              : null}
          </View>
        </View>

        <View
          wrap={false}
          style={{
            borderBottom: "1px solid black",
            borderLeft: "1px solid black",
            borderTop: "0.5px solid black",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Text
            style={{
              padding: "5px",
              fontSize: "12px",
              height: "auto",
              width: "39.95%",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            Balance
          </Text>

          <Text
            style={{
              padding: "5px",
              fontSize: "12px",
              height: "auto",
              width: "60%",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              borderRight: "1px solid black",
              textAlign: "right",
            }}
          >
            {Number(grandTotalValue)?.toFixed(2)}
          </Text>
        </View>

        {/* total Of summary */}
        {/* <View style={{ marginTop: 40 }}>
          <Text
            style={{ fontSize: 12, fontFamily: "Times", fontWeight: "bold" }}
          >
            Summary
          </Text>
          <View
            style={{
              border: "1px solid black",
              flexDirection: "row",
              width: "440px",
              backgroundColor: "#d3cfcf",
            }}
            fixed
          >
            <Text
              style={{
                padding: "5px",
                width: "110px",
                fontSize: "9px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Purchase Value
            </Text>
            <Text
              style={{
                padding: "5px",
                width: "110px",
                fontSize: "9px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Sale Value
            </Text>
            <Text
              style={{
                padding: "5px",
                fontSize: "9px",
                width: "110px",
                fontWeight: "bold",
                fontFamily: "Times",
                alignItems: "center",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Expense Value
            </Text>
            <Text
              style={{
                padding: "5px",
                fontSize: "9px",
                width: "110px",
                fontWeight: "bold",
                fontFamily: "Times",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              Value
            </Text>
          </View>
          <View
            wrap={false}
            style={{
              borderBottom: "1px solid black",
              borderLeft: "1px solid black",
              borderTop: "0.5px solid black",
              flexDirection: "row",
              width: "440px",
            }}
          >
            <Text
              style={{
                padding: "5px",
                fontSize: "8px",
                height: "auto",
                width: "109.5px",
                color: "green",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              {Number(totalPurchaseValue)?.toFixed(2)}
            </Text>

            <Text
              style={{
                padding: "5px",
                fontSize: "8px",
                height: "auto",
                width: "109.5px",
                color: "green",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              {Number(totalSaleValue)?.toFixed(2)}
            </Text>
            <Text
              style={{
                padding: "5px",
                fontSize: "8px",
                height: "auto",
                width: "109.6px",
                color: "green",
                borderRight: "1px solid black",
                fontWeight: "bold",
                fontFamily: "Times",
                textAlign: "center",
              }}
            >
              {Number(totalExpenseValue)?.toFixed(2)}
            </Text>
            <Text
              style={{
                padding: "5px",
                fontSize: "8px",
                height: "auto",
                width: "110px",
                color: "green",
                borderRight: "1px solid black",
                fontWeight: "bold",
                fontFamily: "Times",
                textAlign: "center",
              }}
            >
              {(
                Number(totalPurchaseValue) +
                Number(totalExpenseValue) -
                Number(totalSaleValue)?.toFixed(2)
              )?.toFixed(2)}
            </Text>
          </View>
        </View> */}

        {/* Signature part */}
        <View
          wrap={false}
          fixed
          style={{
            flexDirection: "row",
            bottom: 0,
            marginTop: 60,
            justifyContent: "space-around",
          }}
        >
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Received Name
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Customer Seal & Signature
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Authorised Signature
              </Text>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export const chunkSubstr = (str, size) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};

Font.registerHyphenationCallback((word) => {
  if (word.length > 16) {
    return chunkSubstr(word, 14);
  } else {
    return [word];
  }
});

Font.register({
  family: "Arial",
  fonts: [
    {
      src: "/assets/fonts/ARIALN.TTF",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/ARIALN.TTF",
    },
    {
      src: "/assets/fonts/ARIALBD.TTF",
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Helvetica",
  fonts: [
    {
      src: "/assets/fonts/Helvetica.ttf",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/Helvetica Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Times",
  fonts: [
    {
      src: "/assets/fonts/Times-Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  body: {
    paddingTop: 25,
    paddingBottom: 60,
    paddingHorizontal: 20,
    fontFamily: "Helvetica",
  },

  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
